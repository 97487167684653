import * as React from 'react'
import './contentHistory.css'

export const Upcoming = (props: any) =>
  <svg width={props.svgwidth} height={props.svgheight}viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="44" height="44" fill="white"/>
    <g clipPath="url(#clip0_1_81)">
      <rect width="36" height="36" transform="translate(4 4)" fill="white"/>
      <path d="M39.1818 4.81818H4.81818V39.1818H39.1818V4.81818Z" stroke="#1E4E69" strokeWidth="2"
            strokeMiterlimit="10"/>
      <path d="M4.81818 9.72729H39.1818" stroke="#1E4E69" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M7.27271 7.27271H8.90907" stroke="#1E4E69" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M10.5455 7.27271H12.1818" stroke="#1E4E69" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M22 13.8182H8.90912V26.0909H22V13.8182Z" fill="#72CAC2" fillOpacity="0.5" stroke="#1E4E69"
            strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M25.2727 13.8182H35.9091" stroke="#FBE57F" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M25.2727 17.9091H35.9091" stroke="#FBE57F" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M25.2727 22H35.9091" stroke="#FBE57F" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M25.2727 26.0909H35.9091" stroke="#FBE57F" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M8.09088 31H35.9091" stroke="#FBE57F" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M8.09088 35.0909H35.9091" stroke="#FBE57F" strokeWidth="2" strokeMiterlimit="10"/>
    </g>
    <defs>
      <clipPath id="clip0_1_81">
        <rect width="36" height="36" fill="white" transform="translate(4 4)"/>
      </clipPath>
    </defs>
  </svg>
import { filterBy } from '@progress/kendo-data-query';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import classnames from 'classnames'
import React, { useState } from 'react'
import './_styles.scss'

export const InputSearch  = (props) => {
 const {placeholder, className, textFiled, value, isDisabled} = props
 const [searchReasult, setSearchReasult] = useState(props.data)

 const filterData = (filter) => {
    const data = props.data.slice();
    return filterBy(data, filter)
  }

 const filterChange = (event) => {
    setSearchReasult(filterData(event.filter))
  }

 const onChange = (event) => {
    props.onChange(event.target.value)
  }

 return <ComboBox
        className={classnames('autocompleteTextBox', className || '')}
        placeholder={placeholder}
        data={searchReasult}
        textField={textFiled}
        filterable={true}
        value= {value ? value : null}
        onChange={onChange}
        disabled= {isDisabled}
        onFilterChange={filterChange}
        />
}

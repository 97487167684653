import * as React from 'react'
import './your-membership.css'

export const YourMembershipIcon = (props: any) =>
<svg className={`system-icon ${props.className}`} version="1.1" id="Layer_1" height="34px" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
		 viewBox="0 0 302.9 56.7" enableBackground="new 0 0 302.9 56.7">
<g>
	<g>
		<path className="st0" d="M12.3,27.5C10.7,31.5,9,33,5.8,33c-1.9,0-3.3-0.4-4.6-1.2l1.5-3.3c0.8,0.4,1.7,0.7,2.6,0.7
			c1.3,0,2-0.6,2.8-2.3L0,7.4h4.8l5.6,14.5l5.1-14.5h4.7L12.3,27.5z"/>
		<path className="st0" d="M30.9,27.5c-6,0-10.4-4.6-10.4-10.1c0-5.6,4.4-10.3,10.4-10.3s10.4,4.6,10.4,10.2
			C41.3,22.8,36.9,27.5,30.9,27.5z M30.9,11c-3.6,0-5.9,2.8-5.9,6.3c0,3.5,2.5,6.3,6,6.3c3.6,0,5.9-2.8,5.9-6.3
			C36.9,13.8,34.4,11,30.9,11z"/>
		<path className="st0" d="M57.6,7.5h4.5V27h-4.5v-3c-1.3,1.9-3.1,3.4-6.1,3.4c-4.4,0-7-3-7-7.5V7.5H49v11.1c0,3,1.5,4.8,4.2,4.8
			c2.6,0,4.4-1.8,4.4-4.8V7.5z"/>
		<path className="st0" d="M71.2,27h-4.5V7.5h4.5v4.4C72.4,9,74.7,7,78.1,7.1v4.7h-0.3c-3.9,0-6.7,2.6-6.7,7.7V27z"/>
		<path className="st1" d="M104.4,7.5c4.4,0,7.1,3,7.1,7.7V27h-2.9V15.8c0-3.7-1.9-5.8-5-5.8c-2.9,0-5.3,2.1-5.3,5.9v11h-2.8V15.8
			c0-3.6-1.9-5.7-4.9-5.7s-5.3,2.5-5.3,6V27h-2.8V7.9h2.8v3.2c1.3-1.9,3-3.6,6.2-3.6c3.1,0,5.1,1.7,6.1,3.8
			C99.1,9.2,101.1,7.5,104.4,7.5z"/>
		<path className="st1" d="M125.1,25c2.6,0,4.5-1.1,6-2.7l1.8,1.6c-1.9,2.1-4.3,3.6-7.9,3.6c-5.3,0-9.5-4-9.5-10c0-5.6,3.9-10,9.2-10
			c5.7,0,8.9,4.5,8.9,10.1c0,0.3,0,0.6,0,0.9h-15.2C118.7,22.7,121.7,25,125.1,25z M130.7,16.4c-0.3-3.5-2.3-6.5-6.1-6.5
			c-3.3,0-5.8,2.8-6.2,6.5H130.7z"/>
		<path className="st1" d="M159.3,7.5c4.4,0,7.1,3,7.1,7.7V27h-2.9V15.8c0-3.7-1.8-5.8-5-5.8c-2.9,0-5.3,2.1-5.3,5.9v11h-2.8V15.8
			c0-3.6-1.9-5.7-4.9-5.7c-3,0-5.3,2.5-5.3,6V27h-2.8V7.9h2.8v3.2c1.3-1.9,3-3.6,6.2-3.6c3.1,0,5.1,1.7,6.1,3.8
			C154,9.2,156,7.5,159.3,7.5z"/>
		<path className="st1" d="M181.4,27.4c-3.6,0-5.8-1.9-7.3-4.1V27h-2.8V0h2.8v11.7c1.6-2.3,3.8-4.3,7.3-4.3c4.6,0,9.1,3.6,9.1,10
			C190.6,23.7,186.1,27.4,181.4,27.4z M180.9,10c-3.6,0-6.9,3-6.9,7.4c0,4.5,3.4,7.4,6.9,7.4c3.7,0,6.7-2.7,6.7-7.4
			C187.7,12.9,184.5,10,180.9,10z"/>
		<path className="st1" d="M203.1,25c2.6,0,4.5-1.1,6-2.7l1.8,1.6c-1.9,2.1-4.3,3.6-7.9,3.6c-5.3,0-9.5-4-9.5-10c0-5.6,3.9-10,9.2-10
			c5.7,0,8.9,4.5,8.9,10.1c0,0.3,0,0.6,0,0.9h-15.2C196.7,22.7,199.7,25,203.1,25z M208.6,16.4c-0.3-3.5-2.3-6.5-6.1-6.5
			c-3.3,0-5.8,2.8-6.2,6.5H208.6z"/>
		<path className="st1" d="M218.4,27h-2.8V7.9h2.8v5c1.4-3.2,4.2-5.5,7.8-5.3v3.1H226c-4.2,0-7.6,3-7.6,8.8V27z"/>
		<path className="st1" d="M235.5,27.4c-2.7,0-5.8-1.1-7.9-2.8l1.4-2c2.1,1.6,4.4,2.4,6.6,2.4c2.3,0,4-1.2,4-3v-0.1
			c0-1.9-2.3-2.7-4.8-3.4c-3-0.9-6.3-1.9-6.3-5.4V13c0-3.3,2.7-5.5,6.5-5.5c2.3,0,4.9,0.8,6.9,2.1l-1.3,2.1
			c-1.8-1.1-3.8-1.9-5.7-1.9c-2.3,0-3.7,1.2-3.7,2.8v0.1c0,1.8,2.4,2.5,4.9,3.3c3,0.9,6.1,2,6.1,5.5v0.1
			C242.3,25.3,239.3,27.4,235.5,27.4z"/>
		<path className="st1" d="M248.8,27H246V0h2.8v11.2c1.3-2.1,3.3-3.7,6.6-3.7c4.6,0,7.3,3.1,7.3,7.7V27h-2.9V15.8c0-3.6-1.9-5.8-5.3-5.8
			c-3.3,0-5.7,2.4-5.7,6V27z"/>
		<path className="st1" d="M267.5,3.7V0.6h3.3v3.1H267.5z M267.6,27V7.9h2.8V27H267.6z"/>
		<path className="st1" d="M286.2,27.4c-3.6,0-5.8-1.9-7.3-4.1v9.6H276V7.9h2.8v3.8c1.6-2.3,3.8-4.3,7.3-4.3c4.6,0,9.1,3.6,9.1,10
			C295.3,23.7,290.8,27.4,286.2,27.4z M285.7,10c-3.6,0-6.9,3-6.9,7.4c0,4.5,3.4,7.4,6.9,7.4c3.7,0,6.7-2.7,6.7-7.4
			C292.4,12.9,289.3,10,285.7,10z"/>
	</g>
</g>
<g>
	<path className="st1" d="M301.5,7.8c0.4,0.2,0.7,0.6,1,1c0.2,0.4,0.4,0.9,0.4,1.4c0,0.5-0.1,1-0.4,1.4c-0.2,0.4-0.6,0.7-1,1
		s-0.9,0.3-1.3,0.3c-0.5,0-0.9-0.1-1.3-0.3c-0.4-0.2-0.7-0.6-1-1c-0.2-0.4-0.4-0.9-0.4-1.4c0-0.5,0.1-1,0.4-1.4c0.2-0.4,0.6-0.7,1-1
		c0.4-0.2,0.9-0.3,1.3-0.3C300.7,7.4,301.1,7.5,301.5,7.8z M301.3,12.1c0.3-0.2,0.6-0.5,0.8-0.8c0.2-0.3,0.3-0.7,0.3-1.2
		c0-0.4-0.1-0.8-0.3-1.2c-0.2-0.3-0.4-0.6-0.8-0.8s-0.7-0.3-1.1-0.3S299.4,8,299,8.2s-0.6,0.5-0.8,0.8c-0.2,0.3-0.3,0.7-0.3,1.2
		c0,0.4,0.1,0.8,0.3,1.2c0.2,0.3,0.5,0.6,0.8,0.8s0.7,0.3,1.1,0.3S301,12.3,301.3,12.1z M301.3,11.4c0,0.1,0,0.1-0.1,0.2
		s-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.3-0.2s-0.1-0.3-0.1-0.5c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1h-0.5v0.9
		c0,0,0,0.1-0.1,0.1s-0.1,0.1-0.2,0.1s-0.1,0-0.2-0.1c0,0-0.1-0.1-0.1-0.1V8.9c0,0,0-0.1,0.1-0.1s0.1-0.1,0.1-0.1h0.8
		c0.3,0,0.6,0.1,0.8,0.2s0.3,0.3,0.3,0.6c0,0.4-0.2,0.6-0.6,0.7c0.1,0.1,0.2,0.2,0.3,0.3s0.1,0.3,0.1,0.4c0,0.1,0,0.2,0,0.3
		c0,0,0.1,0.1,0.1,0.1C301.3,11.2,301.3,11.3,301.3,11.4z M300.6,9.9c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2,0-0.3-0.1-0.3
		c-0.1-0.1-0.2-0.1-0.4-0.1h-0.6V10h0.5C300.4,10,300.5,9.9,300.6,9.9z"/>
</g>
<title>behaviourwatch 1</title>
<title>schoolpod 1</title>
<title>schoolmoney 1</title>
<g>
	<g>
		<g>
			<path className="st1" d="M230.5,40.6L230.5,40.6c-0.3-0.3-0.6-0.4-1-0.4c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1v5.8
				c0,0.8-0.2,1.4-0.6,1.8c-0.4,0.4-1,0.6-1.7,0.6c-0.7,0-1.3-0.2-1.7-0.6c-0.4-0.4-0.6-1-0.6-1.9v-5.8c0-0.4-0.1-0.8-0.4-1
				c-0.3-0.3-0.6-0.4-1-0.4c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1v6.6c0,0.8,0.2,1.6,0.5,2.2c0.3,0.7,0.8,1.2,1.4,1.5
				c0.6,0.4,1.3,0.6,2.1,0.6c1.5,0,2.7-0.4,3.4-1.2c0,1-0.2,1.7-0.7,2.1c-0.5,0.4-1.2,0.6-2.2,0.6c-0.3,0-0.6,0-0.9-0.1
				c-0.3-0.1-0.5-0.1-0.8-0.2c-0.3-0.1-0.4-0.2-0.5-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.8,0.3c-0.2,0.2-0.4,0.5-0.5,0.8
				c0,0.1-0.1,0.3-0.1,0.4c0,0.6,0.4,1.1,1.1,1.4c0.3,0.1,0.8,0.2,1.3,0.3c0.5,0.1,1,0.1,1.5,0.1c1.7,0,3.1-0.4,4.2-1.3
				c1-0.9,1.6-2.3,1.6-4.3v-9.5C230.9,41.2,230.7,40.8,230.5,40.6z"/>
			<path className="st1" d="M128.6,49c-0.2,0-0.4,0-0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.2-0.3,0.2
				c-0.3,0.2-0.9,0.3-1.6,0.3c-0.9,0-1.7-0.3-2.3-1c-0.6-0.7-0.9-1.5-0.9-2.6c0-1.1,0.3-1.9,0.9-2.6c0.6-0.7,1.4-1,2.5-1
				c0.2,0,0.4,0,0.7,0.1c0.3,0.1,0.5,0.1,0.6,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.6,0.1
				c0.3,0,0.6-0.1,0.8-0.4c0.2-0.3,0.3-0.6,0.3-0.9c0-0.7-0.4-1.2-1.2-1.7c-0.8-0.4-1.8-0.6-3.1-0.6c-1.1,0-2.2,0.3-3,0.8
				c-0.9,0.5-1.6,1.3-2.1,2.2c-0.5,0.9-0.8,2-0.8,3.2c0,1.2,0.2,2.2,0.7,3.2c0.5,0.9,1.2,1.7,2,2.2c0.9,0.5,1.9,0.8,3,0.8
				c0.8,0,1.5-0.1,2.2-0.3c0.7-0.2,1.2-0.4,1.7-0.8c0.4-0.3,0.7-0.7,0.7-1.1c0-0.4-0.1-0.7-0.3-1C129.3,49.1,129,49,128.6,49z"/>
			<path className="st1" d="M201.7,40.1c-0.8,0-1.5,0.2-2.2,0.6c-0.5,0.3-1,0.7-1.4,1.1v0c0-0.4-0.1-0.8-0.4-1c-0.3-0.3-0.6-0.4-1-0.4
				c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1v10.5h2.9v-7.1c0-0.7,0.2-1.3,0.7-1.8c0.5-0.5,1.1-0.7,1.9-0.7
				c0.8,0,1.3,0.2,1.6,0.6c0.3,0.4,0.5,1.1,0.5,1.8v7.1h2.9v-7.1c0-1.6-0.3-2.8-0.9-3.7C204.3,40.5,203.2,40.1,201.7,40.1z"/>
			<path className="st1" d="M160.6,41.4c-0.6-0.9-1.6-1.4-3.1-1.4c-0.9,0-1.7,0.3-2.4,0.8c-0.6,0.4-1.1,0.9-1.4,1.5
				c-0.6-1.5-1.8-2.3-3.6-2.3c-0.8,0-1.5,0.2-2.1,0.6c-0.5,0.3-1,0.7-1.3,1.1v0c0-0.4-0.1-0.8-0.4-1c-0.3-0.3-0.6-0.4-1-0.4
				c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1l0,10.5h2.9l0-7.1c0-0.7,0.2-1.3,0.7-1.8c0.4-0.5,1.1-0.7,1.8-0.7
				c0.7,0,1.3,0.2,1.6,0.6c0.3,0.4,0.5,1.1,0.5,1.8l0,7.1h2.9l0-7.1c0-0.7,0.2-1.3,0.7-1.8c0.4-0.5,1.1-0.7,1.8-0.7
				c0.7,0,1.3,0.2,1.6,0.6c0.3,0.4,0.5,1.1,0.5,1.8l0,7.1h2.9l0-7.1C161.4,43.6,161.2,42.3,160.6,41.4z"/>
			<path className="st1" d="M209,36.5c-0.5,0-0.9,0.1-1.1,0.3c-0.3,0.2-0.4,0.5-0.4,1v0.4c0,0.4,0.1,0.8,0.4,0.9
				c0.2,0.2,0.6,0.3,1.1,0.3c0.5,0,0.9-0.1,1.2-0.3c0.2-0.2,0.4-0.5,0.4-1v-0.4c0-0.4-0.1-0.8-0.4-0.9
				C209.9,36.6,209.5,36.5,209,36.5z"/>
			<path className="st1" d="M180.2,41.4c-0.6-0.9-1.6-1.4-3.1-1.4c-0.9,0-1.7,0.3-2.4,0.8c-0.6,0.4-1.1,0.9-1.4,1.5
				c-0.6-1.5-1.8-2.3-3.6-2.3c-0.8,0-1.5,0.2-2.1,0.6c-0.5,0.3-1,0.7-1.3,1.1v0c0-0.4-0.1-0.8-0.4-1c-0.3-0.3-0.6-0.4-1-0.4
				c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1l0,10.5h2.9l0-7.1c0-0.7,0.2-1.3,0.7-1.8c0.4-0.5,1.1-0.7,1.8-0.7
				c0.7,0,1.3,0.2,1.6,0.6c0.3,0.4,0.5,1.1,0.5,1.8l0,7.1h2.9l0-7.1c0-0.7,0.2-1.3,0.7-1.8c0.4-0.5,1.1-0.7,1.8-0.7
				c0.7,0,1.3,0.2,1.6,0.6c0.3,0.4,0.5,1.1,0.5,1.8l0,7.1h2.9l0-7.1C181.1,43.6,180.8,42.3,180.2,41.4z"/>
			<path className="st1" d="M139.5,40.9c-0.9-0.5-2-0.8-3.1-0.8c-1.1,0-2.2,0.3-3.1,0.8c-0.9,0.5-1.7,1.3-2.2,2.2
				c-0.5,0.9-0.8,2-0.8,3.2s0.3,2.3,0.8,3.2c0.5,0.9,1.3,1.7,2.2,2.2c0.9,0.5,2,0.8,3.1,0.8c1.1,0,2.2-0.3,3.1-0.8
				c0.9-0.5,1.7-1.3,2.2-2.2c0.5-0.9,0.8-2,0.8-3.2s-0.3-2.3-0.8-3.2C141.1,42.1,140.4,41.4,139.5,40.9z M139.2,48.1
				c-0.3,0.5-0.7,1-1.2,1.3c-0.5,0.3-1,0.4-1.6,0.4c-0.6,0-1.1-0.1-1.6-0.4c-0.5-0.3-0.9-0.7-1.2-1.3c-0.3-0.5-0.4-1.2-0.4-1.9
				c0-0.7,0.1-1.3,0.4-1.9h0c0.3-0.5,0.7-1,1.2-1.3c0.5-0.3,1-0.4,1.6-0.4c0.6,0,1.1,0.1,1.6,0.4c0.5,0.3,0.9,0.7,1.2,1.3
				c0.3,0.5,0.4,1.2,0.4,1.9C139.6,47,139.5,47.6,139.2,48.1z"/>
			<path className="st1" d="M209,40.5c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1v10.4h2.9V41.9c0-0.4-0.1-0.8-0.4-1
				C209.8,40.6,209.4,40.5,209,40.5z"/>
			<path className="st1" d="M217.7,43.1c0.4,0,0.7-0.1,1-0.4c0.3-0.2,0.4-0.6,0.4-0.9c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.4-1-0.4h-1.4
				V38c0-0.4-0.1-0.8-0.4-1c-0.3-0.3-0.6-0.4-1-0.4c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1v2.5h-0.9c-0.4,0-0.7,0.1-1,0.4
				c-0.3,0.2-0.4,0.6-0.4,0.9c0,0.4,0.1,0.7,0.4,1c0.3,0.3,0.6,0.4,1,0.4h0.9v6c0,1.3,0.3,2.2,1,2.6c0.6,0.4,1.4,0.6,2.4,0.6
				c0.6,0,1.1-0.1,1.5-0.4c0.5-0.3,0.7-0.7,0.7-1.1c0-0.3-0.1-0.6-0.3-0.9c-0.2-0.2-0.4-0.3-0.6-0.3c-0.2,0-0.4,0-0.5,0.1
				c-0.2,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.4-0.1-0.6-0.2c-0.1-0.1-0.2-0.3-0.2-0.6v-5.8H217.7z"/>
			<path className="st1" d="M193,40.7L193,40.7c-0.3-0.3-0.6-0.4-1-0.4c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1l0,5.9
				c0,0.7-0.2,1.3-0.7,1.8c-0.5,0.5-1.1,0.7-1.9,0.7c-0.8,0-1.3-0.2-1.6-0.6c-0.3-0.4-0.5-1.1-0.5-1.8l0-5.9c0-0.4-0.1-0.8-0.4-1
				c-0.3-0.3-0.6-0.4-1-0.4c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1l0,5.9v0.3c0,0.1,0,0.1,0,0.2l0,0.1c0,0,0,0,0,0l0,0.1
				c0,0,0,0,0,0.1c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0.1,1.2,0.4,1.8,0.8,2.5c0.6,0.9,1.7,1.4,3.2,1.4
				c1.2,0,2.7-0.7,3.5-1.5v1.3h2.9l0-10.5C193.4,41.3,193.3,41,193,40.7z"/>
		</g>
		<g>
			<path className="st1" d="M241.5,41.8c-0.8-0.5-1.6-0.7-2.6-0.7c-0.8,0-1.6,0.2-2.3,0.6c-0.5,0.3-0.9,0.7-1.3,1.2v-5.4c0,0,0,0,0,0
				c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.2-0.3-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.3-0.2,0.5c0,0,0,0,0,0l0,14.7h1.6l0-1.6
				c0.4,0.5,0.8,0.8,1.3,1.1c0.7,0.4,1.4,0.6,2.3,0.6c0.9,0,1.8-0.3,2.5-0.7c0.8-0.5,1.4-1.2,1.8-2c0.4-0.9,0.6-1.8,0.6-2.9
				c0-1.1-0.2-2-0.6-2.9C242.8,43,242.2,42.3,241.5,41.8z M241.9,48.8c-0.3,0.6-0.7,1.1-1.3,1.5c-0.5,0.4-1.1,0.5-1.8,0.5
				c-0.7,0-1.3-0.2-1.8-0.5c-0.5-0.4-1-0.9-1.3-1.5c-0.3-0.6-0.5-1.4-0.5-2.1c0-0.8,0.2-1.5,0.5-2.1c0.3-0.6,0.7-1.1,1.3-1.5
				c0.5-0.3,1.2-0.5,1.9-0.5c0.7,0,1.3,0.2,1.8,0.5c0.5,0.3,1,0.8,1.3,1.5c0.3,0.6,0.5,1.3,0.5,2.1
				C242.4,47.5,242.2,48.2,241.9,48.8z"/>
			<path className="st1" d="M251.4,41.3C251.4,41.3,251.4,41.3,251.4,41.3c-0.2-0.1-0.5-0.2-0.8-0.2c-0.9,0-1.7,0.3-2.4,0.8
				c-0.5,0.4-0.8,0.8-1.1,1.3v-1c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.3-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.3-0.2,0.6
				l0,10h1.6l0-5.9c0-0.6,0.2-1.2,0.5-1.8c0.3-0.6,0.7-1.1,1.2-1.4c0.5-0.4,0.9-0.5,1.4-0.5c0.2,0,0.3,0,0.5,0.1
				c0.3,0.1,0.4,0.1,0.5,0.1c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.6C251.8,41.7,251.7,41.5,251.4,41.3z"/>
			<path className="st1" d="M261.2,41.3c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.2,0.6v0.7c-0.3-0.4-0.8-0.8-1.3-1.1
				c-0.7-0.4-1.5-0.6-2.3-0.6c-0.9,0-1.8,0.2-2.5,0.7c-0.8,0.5-1.4,1.2-1.8,2c-0.4,0.8-0.7,1.8-0.7,2.9c0,1.1,0.2,2,0.6,2.9
				c0.4,0.9,1,1.5,1.8,2c0.8,0.5,1.6,0.7,2.6,0.7c0.8,0,1.6-0.2,2.3-0.7c0.5-0.3,0.9-0.7,1.3-1.1l0,1.6h1.6l0-10
				c0-0.2-0.1-0.4-0.2-0.6C261.6,41.4,261.4,41.3,261.2,41.3z M260,48.8c-0.3,0.6-0.7,1.1-1.3,1.5c0,0,0,0,0,0
				c-0.5,0.4-1.1,0.5-1.8,0.5c-0.7,0-1.3-0.2-1.8-0.5c-0.5-0.4-1-0.9-1.3-1.5c-0.3-0.6-0.5-1.4-0.5-2.1c0-0.8,0.2-1.5,0.5-2.1
				c0.3-0.6,0.7-1.1,1.3-1.5c0.5-0.3,1.1-0.5,1.8-0.5c0.7,0,1.3,0.2,1.8,0.5c0.5,0.4,1,0.9,1.3,1.5c0.3,0.6,0.5,1.3,0.5,2.1
				C260.5,47.5,260.3,48.2,260,48.8z"/>
			<path className="st1" d="M269.3,41.1c-0.8,0-1.5,0.2-2.2,0.6c-0.5,0.3-1,0.7-1.3,1.1v-0.6c0-0.2-0.1-0.4-0.2-0.6
				c-0.2-0.2-0.3-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.3-0.2,0.6v10h1.6v-6.8c0-0.5,0.2-1,0.5-1.4
				c0.3-0.4,0.7-0.8,1.2-1c0.5-0.3,1-0.4,1.6-0.4c0.9,0,1.5,0.3,1.9,0.8c0.4,0.5,0.6,1.2,0.6,2v6.8h1.6v-6.8c0-1.3-0.3-2.3-0.9-3.1
				C271.7,41.5,270.7,41.1,269.3,41.1z"/>
			<path className="st1" d="M284.7,36.9c-0.2-0.2-0.3-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.3-0.2,0.6v5.4
				c-0.3-0.4-0.8-0.8-1.3-1.1c-0.7-0.4-1.5-0.6-2.3-0.6c-0.9,0-1.8,0.2-2.5,0.7c-0.8,0.5-1.4,1.2-1.8,2c-0.4,0.9-0.7,1.8-0.7,2.9
				c0,1.1,0.2,2,0.7,2.9c0.4,0.9,1,1.5,1.8,2c0.8,0.5,1.6,0.7,2.5,0.7c0.9,0,1.6-0.2,2.3-0.7c0.5-0.3,0.9-0.7,1.2-1.1v1.6h1.6V37.4
				C284.9,37.2,284.8,37,284.7,36.9C284.7,36.9,284.7,36.9,284.7,36.9z M282.9,48.9c-0.3,0.6-0.7,1.1-1.3,1.5
				c-0.5,0.3-1.1,0.5-1.8,0.5c-0.7,0-1.3-0.2-1.8-0.5c-0.5-0.4-1-0.9-1.3-1.5c-0.3-0.6-0.5-1.3-0.5-2.1c0-0.8,0.2-1.5,0.5-2.1
				c0.3-0.6,0.7-1.1,1.3-1.5c0.5-0.4,1.1-0.5,1.8-0.5c0.7,0,1.3,0.2,1.8,0.5c0.5,0.4,1,0.9,1.3,1.5c0.3,0.6,0.5,1.3,0.5,2.1
				C283.4,47.5,283.2,48.2,282.9,48.9z"/>
			<path className="st1" d="M292.9,46.6c-0.5-0.2-1.1-0.4-1.8-0.6c-0.9-0.2-1.6-0.5-2-0.8c-0.4-0.3-0.6-0.6-0.7-1.1
				c0-0.5,0.2-0.9,0.6-1.2c0.4-0.3,0.9-0.4,1.6-0.4c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.8,0.4,1.1,0.8c0.1,0.1,0.3,0.2,0.5,0.2
				c0.2,0,0.4-0.1,0.5-0.2c0.2-0.2,0.3-0.3,0.3-0.6c0-0.2-0.1-0.4-0.2-0.5c-0.4-0.5-1-0.9-1.6-1.1c-0.6-0.2-1.2-0.4-1.8-0.4
				c-0.7,0-1.3,0.1-1.9,0.4c-0.6,0.3-1,0.6-1.4,1.1c-0.3,0.5-0.5,1-0.5,1.6c0,0.9,0.3,1.7,1,2.2c0.7,0.5,1.6,0.9,2.7,1.1
				c0.8,0.2,1.4,0.4,1.8,0.7c0.4,0.3,0.6,0.6,0.6,1.1c0,0.5-0.2,0.9-0.6,1.2c-0.5,0.3-1,0.4-1.6,0.4c-1.3,0-2.3-0.5-3.1-1.5
				c0,0,0,0,0,0c-0.2-0.2-0.4-0.3-0.6-0.3c-0.2,0-0.3,0.1-0.4,0.2c-0.3,0.2-0.3,0.4-0.3,0.6c0,0.2,0.1,0.3,0.2,0.6
				c1,1.3,2.4,1.9,4.1,1.9c1.1,0,2-0.3,2.8-0.8c0.8-0.6,1.1-1.3,1.1-2.3c0-0.7-0.2-1.3-0.5-1.7C293.8,47.2,293.4,46.9,292.9,46.6z"
				/>
			<path className="st1" d="M296.8,42.6c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.1c0.2-0.1,0.3-0.2,0.3-0.3
				c0-0.2-0.1-0.4-0.4-0.4H296c0,0,0,0-0.1,0c0,0,0,0,0,0.1v1.1c0,0,0,0.1,0,0.1c0,0,0,0,0.1,0s0.1,0,0.1,0c0,0,0,0,0-0.1v-0.4h0.2
				c0.1,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1
				C296.9,42.8,296.9,42.7,296.8,42.6C296.9,42.7,296.8,42.7,296.8,42.6C296.8,42.7,296.8,42.7,296.8,42.6z M296.6,42.1
				c0,0-0.1,0.1-0.2,0.1h-0.2v-0.4h0.2c0.1,0,0.1,0,0.2,0c0,0,0.1,0.1,0.1,0.1C296.6,42,296.6,42.1,296.6,42.1z"/>
			<path className="st1" d="M297.3,41.7c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.2,0.1-0.3,0.2-0.4,0.4
				c-0.1,0.2-0.2,0.4-0.2,0.6s0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.6,0.2s0.4,0,0.6-0.2
				c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.2-0.4,0.2-0.6S297.4,41.8,297.3,41.7z M297.1,42.7c-0.1,0.1-0.2,0.3-0.3,0.3
				c-0.1,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.3,0-0.5-0.1c-0.1-0.1-0.3-0.2-0.3-0.3c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0.1-0.5
				c0.1-0.1,0.2-0.3,0.3-0.3c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5
				C297.2,42.4,297.2,42.6,297.1,42.7z"/>
		</g>
	</g>
	<g>
		<path className="st1" d="M104.1,44c0.6,0.4,1,0.9,1.3,1.5c0.3,0.6,0.5,1.4,0.5,2.2c0,0.8-0.2,1.5-0.5,2.2c-0.3,0.7-0.8,1.2-1.3,1.5
			c-0.6,0.4-1.2,0.6-1.9,0.6c-0.6,0-1.2-0.2-1.7-0.5s-0.9-0.7-1.2-1.2v1c0,0.1,0,0.3-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1
			c-0.1,0-0.3,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3V40.5c0-0.1,0-0.3,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1
			c0.1,0.1,0.1,0.2,0.1,0.3v4.7c0.3-0.5,0.7-0.9,1.2-1.3c0.5-0.3,1.1-0.5,1.7-0.5C102.9,43.5,103.5,43.7,104.1,44z M103.6,50.6
			c0.4-0.3,0.8-0.7,1-1.2c0.2-0.5,0.4-1.1,0.4-1.7c0-0.6-0.1-1.2-0.4-1.7c-0.2-0.5-0.6-0.9-1-1.2c-0.4-0.3-0.9-0.4-1.5-0.4
			c-0.6,0-1.1,0.1-1.5,0.4c-0.4,0.3-0.8,0.7-1,1.2s-0.4,1.1-0.4,1.7c0,0.6,0.1,1.2,0.4,1.7c0.2,0.5,0.6,0.9,1,1.2
			c0.4,0.3,0.9,0.4,1.5,0.4C102.7,51.1,103.2,50.9,103.6,50.6z"/>
		<path className="st1" d="M114.3,43.7c0.1,0.1,0.1,0.2,0.1,0.3v7c0,0.8-0.2,1.5-0.5,2.1c-0.3,0.6-0.8,1-1.4,1.4s-1.2,0.5-2,0.5
			c-0.4,0-0.9,0-1.2-0.1c-0.4-0.1-0.7-0.2-1-0.4c-0.2-0.1-0.3-0.3-0.3-0.5c0,0,0-0.1,0-0.2c0-0.1,0.1-0.2,0.2-0.2
			c0.1-0.1,0.2-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0.7,0.4,1.3,0.6,1.9,0.6c0.9,0,1.6-0.3,2.1-0.8c0.5-0.6,0.8-1.3,0.8-2.3v-0.6
			c-0.3,0.4-0.6,0.8-1.1,1.1c-0.5,0.3-1,0.4-1.6,0.4c-0.9,0-1.6-0.3-2.1-0.8c-0.5-0.5-0.7-1.3-0.7-2.2V44c0-0.1,0-0.2,0.1-0.3
			c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v4.7c0,0.8,0.2,1.3,0.5,1.7c0.3,0.4,0.9,0.6,1.6,0.6
			c0.4,0,0.9-0.1,1.2-0.3c0.4-0.2,0.7-0.5,1-0.9c0.2-0.4,0.4-0.7,0.4-1.1V44c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1
			C114.1,43.5,114.2,43.6,114.3,43.7z"/>
	</g>
</g>
</svg>

export const INTERESTS_IMPORT_SAMPLE_FILE_LOCATION: string =
  'https://s3-us-west-2.amazonaws.com/public.rasa.io/imports_sample/interests_import_sample.csv'

export const SkipHeader: HeaderChoice = {
  key: 'skip',
  description: '* Skip Import *',
  alternatives: [],
}
export interface HeaderChoice {
  alternatives?: string[]
  description: string,
  key: string,
}

export const INTERESTS = 'importhistory'

export const  ImportHistoryPageDetails = {
  importhistory: {
    button: 'Import History',
    description: 'Import History of your contacts',
    goBack: false,
    icon: 'Add',
    header: 'ImportHistory',
    sample: true,
  },
}

export const RUN_TYPE = 'contact'
export const DEFAULT_IMPORT_HISTORY_PAGE_SIZE = 10

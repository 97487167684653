import { copyToClipboard } from 'generic/utility'
import React from 'react'
import { Input as ReactStrapInput, InputProps} from 'reactstrap'
import './styles.css'

export const Input = (props: InputProps) =>
<div className="cursor rasa-input">
  <ReactStrapInput {...props} />
  <i></i>
</div>

export const UrlInput = (props: InputProps) => {
 const {errorMessage, onChange, onKeyPress, placeholder, valid, value} = props
 return <div className="url-input-container">
    <ReactStrapInput
      type="text"
      value={value}
      placeholder={placeholder}
      valid={valid}
      invalid={!valid}
      disabled={props.disabled}
      onChange={onChange}
      onKeyPress={onKeyPress}/>
      {valid && !errorMessage &&
      <span className="input-tip">Press Enter to fetch</span>}
      {!!onKeyPress &&
      <span className="invalid-url">{errorMessage}</span>}
  </div>
}

export const CopyInput = (props: InputProps) => {
  return <div className="copy-input">
    <input type="text" value={props.link} readOnly={true} />
    <button onClick={() => copyToClipboard(props.link)}>Copy</button>
  </div>
}

import * as React from 'react'
import './template-big-and-bold.css'

export const BigAndBoldTemplate = ({className, colors, ...props}: any)  =>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" className="big-and-bold-template" viewBox="0 0 600 854">
<g>
<polyline className="big-and-bold0" points="475.4,855.9 599.7,855.9 599.7,99.5 0,99.5 0,855.9 124.6,855.9 	"/>
<path style={{fill: colors.primary_color}} d="M599.2,0H474.9H124.1H-0.5v146.3h599.7h0.3V0H599.2z"/>
<g>
    <g className="big-and-bold2">
        <path className="big-and-bold3" d="M105,341.9c0.9-0.9,1.9-1.9,3.2-3.1c10.5-10.3,10.1-10.6,20.5-20.9c10.4-10.3,10.4-10.4,20.8-20.7
            c9.9-9.7,25.7-8.3,35.5,1.4c11.6,11.4,12.8,10.3,24.4,21.7c11.6,11.4,13.2,9.9,24.7,21.3c11.5,11.4,7.6,15.3,19.2,26.7
            c11.6,11.4,11,12,22.7,23.4c11.6,11.4,14.3,8.7,26,20.1c11.6,11.4,10.4,12.7,22,24.1c7.1,7,14.5,7.5,19.4,10.6
            c5.7-5.6,9.2-9,19.5-19.1c8.7-8.6,19.6-12.4,30.2-16.8c11-4.4,21.6-10.5,33.4-10.5c11.7,0,23.2,4.1,34.2,8.7
            c10.6,4.3,22.4,8.6,31,17.1c6.7,6.6,9.2,9,12.9,12.6V209.8H105V341.9z"/>
        <path className="big-and-bold4" d="M310.5,481.9c11.4-11.2,9.9-12.7,21.3-24c5.2-5.1,8.7-8.6,11.6-11.4c-4.9-3-12.3-3.7-19.4-10.6
            c-11.6-11.4-10.4-12.7-22-24.1c-11.6-11.4-14.3-8.7-26-20.1c-11.6-11.4-11-12-22.7-23.4s-7.6-15.3-19.2-26.7
            c-11.6-11.4-13.1-9.9-24.7-21.3c-11.6-11.4-12.8-10.3-24.4-21.7c-9.9-9.7-25.7-11.1-35.5-1.4c-10.5,10.3-10.4,10.4-20.8,20.7
            s-10.1,10.6-20.5,20.9c-1.1,1.1-2.2,2.2-3.2,3.1v168.4h176.2c1.8-1.6,3.9-3.4,6.4-6C299.1,493.1,299.1,493.1,310.5,481.9z"/>
        <path className="big-and-bold5" d="M460.5,408.8c-11-4.4-22.4-8.7-34.2-8.7c-11.7,0-22.4,6-33.4,10.5c-10.6,4.3-21.5,8.3-30.2,16.8
            c-10.2,10.2-13.8,13.5-19.5,19.1c-2.8,2.9-6.4,6.3-11.6,11.4c-11.4,11.2-9.9,12.7-21.3,24s-11.4,11.2-22.8,22.5
            c-2.6,2.5-4.7,4.4-6.4,6h223.1v-71.9c-3.6-3.5-6.1-5.9-12.9-12.6C482.8,417.3,471.1,413.1,460.5,408.8z"/>
        <path className="big-and-bold3" d="M122.3,850.6c1.6-1.7,3.6-3.7,6.3-6.4c10.5-10.3,10.4-10.4,20.8-20.7c9.9-9.7,25.7-8.3,35.5,1.4
            c11.6,11.4,12.8,10.3,24.4,21.7c1.8,1.8,3.3,3.2,4.7,4.4h290.2V735.8H105V849C110.7,849.5,116.5,850.1,122.3,850.6z"/>
        <path className="big-and-bold4" d="M126.6,851h87.5c-1.3-1.2-2.8-2.6-4.6-4.3c-11.6-11.4-12.8-10.3-24.4-21.7c-9.9-9.7-25.7-11.1-35.5-1.4
            c-10.5,10.3-10.4,10.4-20.8,20.7c-2.7,2.7-4.7,4.6-6.3,6.3C123.8,850.7,125.2,850.9,126.6,851z"/>
    </g>
    <path style={{fill: colors.primary_color}} d="M94,545.6c0,7.9,6.4,14.3,14.2,14.3h185.6c7.9,0,14.2-6.4,14.2-14.3c0-7.9-6.4-14.3-14.2-14.3H108.3
        C100.4,531.3,94,537.7,94,545.6z"/>
    <path className="big-and-bold6" d="M476.6,645.2c0-4.7-3.9-8.6-8.5-8.6H102.6c-4.7,0-8.5,3.9-8.5,8.6s3.9,8.6,8.5,8.6H468
        C472.7,653.7,476.6,649.9,476.6,645.2z"/>
    <path style={{fill: colors.primary_color}} d="M162.3,691.4c5,0,9.1-4.1,9.1-9.1s-4.1-9.1-9.1-9.1h-59.2c-5,0-9.1,4.1-9.1,9.1s4.1,9.1,9.1,9.1H162.3z"/>
    <path className="big-and-bold6" d="M467.5,606.1H103.1c-5,0-9.1,4.1-9.1,9.1c0,5,4.1,9.1,9.1,9.1h364.3c5,0,9.1-4.1,9.1-9.1
        S472.5,606.1,467.5,606.1z"/>
    <path className="big-and-bold6" d="M467.5,574.5H103.1c-5,0-9.1,4.1-9.1,9.1c0,5,4.1,9.1,9.1,9.1h364.3c5,0,9.1-4.1,9.1-9.1
        C476.6,578.6,472.5,574.5,467.5,574.5z"/>
    <path className="big-and-bold6" d="M104.3,195h56.9c2.5,0,4.6-2.1,4.6-4.6s-2-4.6-4.6-4.6h-56.9c-2.5,0-4.6,2.1-4.6,4.6S101.8,195,104.3,195z"/>
    <path className="big-and-bold6" d="M500.5,185.9h-56.9c-2.5,0-4.6,2.1-4.6,4.6s2,4.6,4.6,4.6h56.9c2.5,0,4.6-2.1,4.6-4.6S503,185.9,500.5,185.9z"/>
</g>
<polyline className="big-and-bold0" points="196.9,117.4 196.9,34.7 402.1,34.7 402.1,117.4 	"/>
</g>
</svg>

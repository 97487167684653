import * as React from 'react'

export const WordPressIcon = (props: any) =>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000.000000 1080.000000"
     className={`system-icon ${props.className}`} preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M9805 8739 c-315 -30 -667 -154 -933 -328 -457 -301 -778 -773 -887
        -1303 -140 -684 68 -1381 560 -1873 594 -595 1495 -769 2265 -437 506 218 905
        629 1109 1144 70 177 116 385 133 600 46 583 -174 1176 -592 1597 -433 436
        -1044 657 -1655 600z m360 -119 c451 -42 853 -225 1174 -535 236 -228 394
        -475 495 -775 207 -616 100 -1270 -294 -1795 -249 -331 -630 -588 -1032 -695
        -195 -51 -294 -64 -508 -64 -214 0 -313 13 -508 64 -619 165 -1132 651 -1331
        1264 -133 410 -132 819 5 1226 144 425 438 795 824 1033 343 211 777 314 1175
        277z"/>
        <path d="M9736 8385 c-331 -54 -644 -205 -895 -433 -82 -74 -221 -233 -255
        -293 l-19 -32 184 6 c101 4 236 10 300 13 112 7 116 6 137 -16 28 -30 28 -62
        0 -94 -20 -23 -33 -27 -113 -32 -49 -4 -97 -10 -106 -13 -14 -5 31 -147 294
        -930 l311 -924 187 562 188 561 -133 363 -133 362 -34 7 c-19 4 -65 10 -102
        14 -78 7 -107 26 -107 73 0 16 8 39 18 51 l19 23 250 -13 c199 -10 296 -10
        477 0 124 7 237 9 250 6 28 -7 51 -55 41 -86 -11 -35 -46 -49 -148 -58 -54 -5
        -99 -11 -102 -13 -2 -3 134 -415 303 -916 169 -502 310 -907 313 -900 13 23
        221 694 249 802 56 215 61 350 20 511 -24 92 -68 187 -158 338 -89 148 -113
        211 -119 306 -11 165 85 292 245 324 l53 11 -68 54 c-218 174 -470 294 -738
        351 -132 29 -474 37 -609 15z"/>
        <path d="M11514 7327 c-1 -200 -20 -300 -103 -542 -32 -93 -167 -484 -300
        -867 -132 -384 -241 -700 -241 -703 0 -19 219 144 314 235 479 456 652 1162
        439 1790 -27 82 -93 233 -106 246 -1 1 -2 -70 -3 -159z"/>
        <path d="M8415 7344 c-154 -362 -169 -814 -39 -1204 123 -369 389 -705 719
        -906 82 -51 146 -86 149 -82 1 1 -181 505 -405 1117 l-407 1115 -17 -40z"/>
        <path d="M9771 5784 c-139 -405 -251 -738 -248 -740 2 -2 53 -16 113 -29 271
        -61 582 -50 851 31 l82 24 -18 43 c-10 23 -130 349 -267 725 -136 375 -250
        682 -254 682 -3 0 -119 -331 -259 -736z"/>
        <path d="M2060 4066 l0 -43 83 -5 c101 -7 160 -30 215 -87 78 -81 76 -74 456
        -1246 l204 -630 29 0 28 0 235 707 c129 389 238 709 241 712 5 5 291 -859 431
        -1301 38 -120 40 -123 68 -123 l28 0 281 843 c155 464 297 876 316 915 69 144
        152 201 308 210 l87 5 0 43 0 44 -431 0 -430 0 3 -42 3 -43 81 -6 c156 -12
        213 -63 200 -183 -6 -58 -343 -1086 -356 -1086 -3 0 -107 306 -230 680 l-224
        681 -45 -3 -44 -3 -226 -672 c-125 -370 -229 -675 -233 -678 -6 -6 -303 888
        -333 1000 -46 179 4 249 190 264 l80 6 3 43 3 42 -510 0 -511 0 0 -44z"/>
        <path d="M10240 4066 l0 -46 63 0 c181 -1 260 -69 287 -250 8 -52 10 -265 8
        -695 -3 -552 -6 -626 -21 -671 -40 -119 -95 -160 -242 -177 l-95 -11 0 -43 0
        -43 525 0 525 0 0 43 0 43 -96 11 c-221 26 -263 111 -264 536 l0 217 118 0
        c269 1 453 33 587 103 86 45 198 159 234 239 40 87 55 173 48 266 -16 200
        -121 348 -312 438 -166 78 -158 77 -797 81 l-568 4 0 -45z m1122 -154 c115
        -56 178 -151 198 -294 26 -183 -47 -355 -180 -423 -69 -35 -137 -45 -300 -45
        l-150 0 0 401 0 401 183 -4 182 -3 67 -33z"/>
        <path d="M5520 3699 c-91 -16 -245 -73 -332 -125 -103 -61 -238 -194 -294
        -289 -99 -170 -133 -344 -100 -517 72 -374 390 -645 806 -688 296 -30 594 78
        791 287 251 267 289 640 94 939 -56 86 -188 211 -283 268 -90 54 -242 109
        -342 125 -77 13 -263 12 -340 0z m324 -144 c155 -41 267 -135 339 -287 64
        -132 82 -215 82 -373 0 -158 -18 -241 -81 -373 -57 -118 -130 -194 -235 -246
        -87 -42 -152 -56 -263 -55 -259 1 -447 156 -528 434 -20 69 -23 103 -23 240 0
        137 3 171 23 240 63 218 203 371 383 419 72 19 230 20 303 1z"/>
        <path d="M15784 3696 c-137 -31 -255 -117 -315 -229 -33 -59 -34 -67 -34 -168
        0 -101 1 -108 37 -181 28 -56 55 -92 104 -137 57 -54 97 -77 317 -186 275
        -135 346 -184 387 -271 45 -91 8 -205 -84 -259 -145 -85 -384 -38 -525 102
        -67 68 -126 178 -144 271 l-12 62 -42 0 -43 0 0 -315 0 -315 39 0 c35 0 40 3
        45 28 4 15 20 50 36 79 l29 51 66 -43 c84 -55 181 -91 282 -105 163 -22 328
        24 459 128 153 121 207 343 126 516 -22 48 -52 88 -97 132 -59 58 -89 75 -327
        195 -145 72 -283 145 -308 163 -115 82 -133 198 -44 283 77 74 207 92 337 47
        104 -35 209 -173 237 -309 l11 -55 50 0 49 0 0 265 0 265 -43 0 c-43 0 -43 0
        -66 -55 -13 -30 -26 -57 -30 -59 -3 -3 -30 10 -58 28 -114 72 -303 103 -439
        72z"/>
        <path d="M17173 3696 c-290 -67 -437 -321 -324 -562 67 -143 138 -198 436
        -343 122 -59 244 -125 273 -146 99 -75 137 -158 118 -261 -11 -57 -86 -126
        -162 -147 -74 -21 -118 -21 -209 -2 -198 42 -345 190 -391 394 l-16 71 -44 0
        -44 0 0 -315 0 -315 40 0 c30 0 42 5 47 18 18 55 54 123 67 128 8 3 30 -6 49
        -20 45 -34 169 -90 237 -107 30 -7 98 -13 150 -12 218 2 410 115 491 287 86
        183 50 357 -102 501 -61 57 -94 77 -331 193 -335 165 -389 210 -391 323 -1 64
        34 115 104 154 49 27 62 30 149 30 82 0 103 -4 152 -27 107 -50 185 -156 221
        -298 l14 -55 47 -3 46 -3 0 265 0 266 -44 0 c-40 0 -45 -2 -51 -28 -3 -15 -14
        -42 -25 -59 l-19 -32 -58 35 c-118 71 -297 100 -430 70z"/>
        <path d="M6690 3611 l0 -41 45 0 c130 0 203 -44 233 -139 15 -50 17 -107 17
        -531 0 -522 -3 -554 -57 -616 -35 -40 -105 -64 -185 -64 l-53 0 0 -45 0 -45
        455 0 455 0 0 44 0 43 -79 6 c-84 5 -110 15 -154 55 -45 43 -58 106 -64 310
        l-6 182 42 0 41 0 257 -321 256 -320 756 4 c820 5 819 5 996 63 99 33 143 55
        221 110 88 62 167 153 213 248 57 115 74 196 74 346 0 220 -56 363 -199 506
        -69 70 -96 89 -185 133 -208 101 -286 111 -890 111 l-479 0 0 -40 0 -40 45 0
        c24 0 68 -5 97 -11 63 -14 119 -64 139 -124 17 -54 25 -914 9 -1018 -22 -141
        -75 -188 -221 -195 -143 -7 -273 45 -412 163 -81 69 -370 379 -365 392 1 4 34
        17 73 28 143 42 250 126 308 245 30 60 32 72 32 165 0 93 -2 105 -33 167 -56
        114 -167 197 -327 244 -53 16 -118 18 -557 21 l-498 4 0 -40z m946 -124 c69
        -35 110 -87 136 -171 38 -126 7 -260 -80 -336 -62 -55 -119 -70 -269 -70
        l-123 0 0 306 0 306 143 -4 c130 -4 146 -6 193 -31z m1751 17 c200 -41 328
        -165 385 -371 28 -104 31 -351 5 -457 -51 -204 -170 -331 -358 -382 -94 -25
        -304 -26 -343 -1 -61 40 -61 36 -64 660 l-3 567 151 0 c98 0 177 -5 227 -16z"/>
        <path d="M12030 3612 l0 -39 76 -6 c126 -11 170 -43 202 -146 15 -50 17 -108
        17 -526 0 -420 -2 -476 -18 -527 -9 -31 -29 -70 -43 -87 -30 -36 -103 -61
        -181 -61 l-53 0 0 -45 0 -45 455 0 455 0 0 44 0 43 -78 5 c-181 10 -222 84
        -222 401 l0 147 39 0 39 0 256 -320 256 -320 939 0 938 0 6 93 c4 50 10 147
        13 215 l7 123 -33 -3 c-31 -3 -34 -6 -50 -63 -50 -179 -107 -212 -380 -222
        -298 -11 -357 24 -380 223 -11 103 -14 361 -2 367 4 2 61 1 127 -3 199 -13
        242 -43 252 -185 l6 -75 38 0 39 0 0 335 0 335 -39 0 -38 0 -6 -75 c-10 -141
        -52 -172 -251 -186 -65 -5 -123 -6 -127 -3 -5 3 -9 120 -9 261 l0 255 233 -5
        c246 -5 294 -12 357 -57 36 -26 74 -99 91 -177 12 -53 12 -53 51 -53 38 0 39
        1 34 28 -2 15 -10 109 -17 210 l-12 182 -674 0 -673 0 0 -40 0 -40 45 0 c84 0
        147 -20 187 -59 62 -63 63 -71 63 -616 0 -444 -2 -495 -18 -541 -29 -82 -79
        -120 -174 -130 -118 -13 -278 65 -428 207 -86 82 -313 329 -315 344 -1 5 26
        16 58 25 153 42 288 150 332 265 77 206 -8 413 -212 513 -135 66 -133 65 -680
        69 l-498 4 0 -39z m942 -125 c101 -50 152 -141 152 -272 0 -99 -26 -168 -84
        -226 -64 -64 -117 -79 -277 -79 l-123 0 0 306 0 306 138 -4 c126 -3 141 -5
        194 -31z"/>
    </g>
</svg>

import * as React from 'react'

export const Upgrade = (props: any) =>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width={props.svgwidth} height={props.svgwidth} viewBox="0 0 225.000000 225.000000" preserveAspectRatio="xMidYMid meet">
  <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#EE7470" stroke="none">
    <path d="M975 2233 c-86 -13 -235 -58 -309 -92 -241 -111 -452 -324 -557 -561
    -77 -172 -112 -403 -90 -575 36 -274 140 -487 326 -672 145 -144 306 -236 505
    -290 96 -25 120 -28 280 -28 160 0 184 3 278 28 396 106 702 412 804 802 20
    80 23 111 22 290 0 188 -2 205 -27 290 -99 325 -317 579 -614 716 -143 66
    -263 92 -438 95 -82 1 -163 0 -180 -3z m289 -558 c63 -129 120 -237 128 -240
    7 -3 123 -21 257 -40 134 -19 246 -37 250 -41 3 -3 -79 -88 -183 -188 l-188
    -181 32 -185 c17 -102 38 -218 45 -258 7 -40 10 -74 6 -76 -3 -2 -105 50 -226
    115 -121 65 -226 118 -233 119 -13 0 -198 -92 -369 -184 -55 -30 -103 -52
    -106 -50 -2 3 4 51 15 107 10 56 30 172 44 257 l27 155 -189 184 c-188 184
    -189 185 -159 192 17 5 131 22 255 39 124 17 230 37 237 43 10 10 150 286 218
    430 9 20 19 37 21 37 2 0 55 -106 118 -235z"/>
  </g>
</svg>

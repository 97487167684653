import { sizedUrl } from 'app/utils'
import { Upload } from 'components/icons/upload2'
import React from 'react'
import Dropzone from 'react-dropzone'
import './styles.css'

interface ImageUploadProps {
  imageUrl: string,
  showCloseButton?: boolean,
  closeButtonClick?: any,
  uploadImage: any,
}
export const ImageUpload = (props: ImageUploadProps) => {
 return <div className="logo-uploader">
          <div className="small-title">
            Upload logo
          </div>
          <div className="description">
            Use a high quality image. Only JPG or PNG allowed.
          </div>
          <Dropzone onDrop={(acceptedFiles) => props.uploadImage(acceptedFiles[0])}
          accept="image/*">
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()} className="lined-box clickable-item">
                  <input {...getInputProps()} />

                  <div className="image-or-placeholder-wrapper">
                    {props.showCloseButton && props.imageUrl &&
                      <div className="close-icon-container" onClick={(e) => {
                          props.closeButtonClick()
                          e.stopPropagation()
                        }}>
                        <i className="fa fa-window-close" aria-hidden="true" />
                      </div>}
                    <div className="image-wrapper">
                      {props.imageUrl ?
                        <div>
                          <img src={sizedUrl(props.imageUrl, 100, 200, false)}/>
                        </div> :
                      <div className="placeholder-wrapper">
                        <div className="upload-wrapper">
                          <div className="upload"><Upload/></div>
                        </div>
                        <p className="row1-text">Choose your file</p>
                        <p className="row2-text">You may drag and drop your file as well.</p>
                        <p className="row3-text">Upload only PNG, JPG.</p>
                      </div>}
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
          </div>
}

import * as React from 'react'
import './unsubscribed.css'

export const Unsubscribed = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" width={props.svgwidth} height={props.svgheight} className="panel-icon" viewBox="0 0 30.387 26.883">
    <g id="Group_6355" data-name="Group 6355" transform="translate(-446.532 -443.684)">
        <g id="Group_6329" data-name="Group 6329" transform="translate(446.532 443.684)">
            <g id="Group_6326" data-name="Group 6326">
                <path id="Path_21284" d="M682.241 201.28a8.492 8.492 0 0 0-6.092 5.72 12.117 12.117 0 0 0 1.314 1.014c-.1-.1-.207-.194-.307-.291a10.109 10.109 0 0 1 5.085-6.443z" className="unsubscribed-1" data-name="Path 21284" transform="translate(-670.911 -184.594)"/>
                <path id="Path_21285" d="M680.074 195.666a4.956 4.956 0 0 1 3.768-4.492c-.041 0-.078-.012-.12-.012a4.249 4.249 0 0 0-.732 8.435 4.435 4.435 0 0 1-2.916-3.931z" className="unsubscribed-1" data-name="Path 21285" transform="translate(-670.351 -186.301)"/>
                <path id="Path_21286" d="M684.862 188.043a12.17 12.17 0 0 0-12.155 12.157 12.023 12.023 0 0 0 3.079 8.071 9.718 9.718 0 0 1 18.154 0 12.023 12.023 0 0 0 3.078-8.071 12.17 12.17 0 0 0-12.156-12.157zm0 13.371a5.47 5.47 0 1 1 5.471-5.47 5.476 5.476 0 0 1-5.471 5.47z" className="unsubscribed-1" data-name="Path 21286" transform="translate(-671.492 -186.827)"/>
                <path id="Path_21287" d="M687.889 195.416a4.254 4.254 0 0 0-4.135-4.243 4.956 4.956 0 0 0-3.768 4.492 4.435 4.435 0 0 0 2.914 3.935 4.208 4.208 0 0 0 .732.074 4.259 4.259 0 0 0 4.257-4.258z" className="unsubscribed-2" data-name="Path 21287" transform="translate(-670.263 -186.299)"/>
                <path id="Path_21288" d="M683.9 190.123a5.47 5.47 0 1 0 5.471 5.47 5.477 5.477 0 0 0-5.471-5.47zm-4.254 5.47a4.259 4.259 0 0 1 4.254-4.254c.042 0 .079.011.12.012a4.249 4.249 0 0 1-.12 8.5 4.206 4.206 0 0 1-.732-.074 4.254 4.254 0 0 1-3.526-4.184z" className="unsubscribed-3" data-name="Path 21288" transform="translate(-670.526 -186.476)"/>
                <path id="Path_21289" d="M684.136 201.044a8.389 8.389 0 0 0-2.041.276 10.109 10.109 0 0 0-5.085 6.442c.1.1.206.194.307.291a12.123 12.123 0 0 0 14.951-1.014 8.513 8.513 0 0 0-8.132-5.995z" className="unsubscribed-2" data-name="Path 21289" transform="translate(-670.765 -184.633)"/>
                <path id="Path_21290" d="M698.409 200.374a13.371 13.371 0 0 0-26.742 0 13.229 13.229 0 0 0 3.879 9.4l-.006.021.259.247a13.329 13.329 0 0 0 17.132 1.12 13.714 13.714 0 0 0 1.345-1.118l.256-.243-.006-.021a13.229 13.229 0 0 0 3.883-9.406zm-13.371 12.156a12.436 12.436 0 0 1-8.132-3.122 8.492 8.492 0 0 1 6.094-5.719 8.389 8.389 0 0 1 2.041-.276 8.513 8.513 0 0 1 8.132 5.995 12.1 12.1 0 0 1-8.135 3.122zm9.078-4.085a9.718 9.718 0 0 0-18.154 0 12.023 12.023 0 0 1-3.079-8.071 12.156 12.156 0 0 1 24.311 0 12.023 12.023 0 0 1-3.079 8.071z" className="unsubscribed-3" data-name="Path 21290" transform="translate(-671.667 -187.003)"/>
            </g>
            <g id="Group_6328" data-name="Group 6328" transform="translate(16.362 12.858)">
                <path id="Path_21291" d="M702.042 233.517a4.8 4.8 0 0 0-2.65.8l6.776 6.778a4.8 4.8 0 0 0 .8-2.65 4.941 4.941 0 0 0-4.926-4.928z" className="unsubscribed-4" data-name="Path 21291" transform="translate(-695.029 -231.43)"/>
                <path id="Path_21292" d="M702.37 243.039a4.8 4.8 0 0 0 2.651-.8l-6.776-6.778a4.79 4.79 0 0 0-.8 2.651 4.93 4.93 0 0 0 4.925 4.927z" className="unsubscribed-4" data-name="Path 21292" transform="translate(-695.358 -231.101)"/>
                <path id="Path_21293" d="M702.587 232.231a6.4 6.4 0 0 0-4.541 1.879 6.426 6.426 0 0 0 9.078 9.1l.015-.011.011-.015a6.425 6.425 0 0 0-4.556-10.958zm0 11.938a5.481 5.481 0 0 1-4.189-9.05l7.729 7.73a5.463 5.463 0 0 1-3.54 1.32zm4.19-1.969l-7.729-7.73a5.482 5.482 0 0 1 7.729 7.73z" className="unsubscribed-2" data-name="Path 21293" transform="translate(-695.575 -231.647)"/>
                <path id="Path_21294" d="M702.672 231.731a6.964 6.964 0 0 0-4.888 1.986l-.068.065-.064.065a7.012 7.012 0 0 0 5.026 11.9 7.063 7.063 0 0 0 1.305-.126c.022 0 .044-.006.065-.011a6.867 6.867 0 0 0 1.189-.354l.111-.04a7.068 7.068 0 0 0 1.079-.566c.035-.022.071-.042.106-.065a6.973 6.973 0 0 0 .981-.785c.015-.014.033-.023.047-.037l.009-.006.07-.068.058-.06.006-.012c.014-.013.023-.03.036-.044a7 7 0 0 0 .78-.969c.029-.043.053-.089.081-.133a7.051 7.051 0 0 0 .554-1.053c.016-.042.029-.085.046-.127a6.918 6.918 0 0 0 .353-1.18c0-.021.006-.042.009-.064a7 7 0 0 0 .127-1.31 7.02 7.02 0 0 0-7.018-7.006zm4.556 11.543l-.011.015-.015.011a6.426 6.426 0 0 1-9.078-9.1 6.426 6.426 0 0 1 9.1 9.079z" className="unsubscribed-3" data-name="Path 21294" transform="translate(-695.659 -231.731)"/>
                <path id="Path_21295" d="M702.17 233.017a5.462 5.462 0 0 0-3.539 1.32l7.729 7.73a5.482 5.482 0 0 0-4.19-9.05zm4.127 8.16l-6.777-6.777a4.8 4.8 0 0 1 2.65-.8 4.941 4.941 0 0 1 4.925 4.926 4.8 4.8 0 0 1-.795 2.651z" className="unsubscribed-3" data-name="Path 21295" transform="translate(-695.157 -231.514)"/>
                <path id="Path_21296" d="M696.945 238.242a5.482 5.482 0 0 0 9.05 4.19l-7.729-7.73a5.458 5.458 0 0 0-1.321 3.54zm8.16 4.127a4.8 4.8 0 0 1-2.651.8 4.93 4.93 0 0 1-4.925-4.925 4.791 4.791 0 0 1 .8-2.651z" className="unsubscribed-3" data-name="Path 21296" transform="translate(-695.442 -231.23)"/>
            </g>
        </g>
    </g>
</svg>

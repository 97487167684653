import * as React from 'react'
import './template-newspaper.css'

export const NewspaperTemplate = ({className, colors, ...props}: any) =>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" className="newspaper-template" viewBox="0 0 600 854">

<g>
<path className="newspaper-template0" d="M0,0v63v165v626h600V228V63V0H0z M35.2,380.1c0-8.1,1.9-8.1,1.9-16.3s-3.1-8.1-3.1-16.2s4-8.1,4-16.3
    s0.5-8.1,0.5-16.2s-1.2-8.1-1.2-16.2s-0.1-8.1-0.1-16.2s2.2-8.1,2.2-16.2s-4.5-8.1-4.5-16.2s-8.6-11,1.5-16.6
    c10.6-5.9,15.2,0.3,29.8,0.3s14.6-0.2,29.3-0.2s14.6-1.3,29.3-1.3s14.6,2,29.3,2s14.6-1,29.3-1s14.6-0.8,29.3-0.8
    s14.6,1.8,29.3,1.8s14.6-1.8,29.3-1.8s14.6,2.3,29.3,2.3s14.6-1.3,29.3-1.3s14.6,2.2,29.3,2.2s14.6-2.5,29.3-2.5s14.6,0,29.3,0
    s14.6,1.5,29.3,1.5c14.6,0,14.6-1.7,29.3-1.7s14.6,1.8,29.3,1.8s14.6-2.4,29.3-2.4c14.6,0,16.7-3.3,27.1,2.7
    c10.3,5.9,4.4,7.2,4.4,15.6s-5.1,8.4-5.1,16.8s4.1,8.4,4.1,16.8s-2.3,8.4-2.3,16.8s2.1,8.4,2.1,16.8s-0.9,8.4-0.9,16.8
    s0,8.4,0,16.8s-2.6,8.4-2.6,16.8s0.9,8.4,0.9,16.8s-1.6,8.4-1.6,16.8s3.3,8.4,3.3,16.8s0.2,8.4,0.2,16.8s2.2,8.4,2.2,16.8
    s-5.8,8.4-5.8,16.8c0,8.1,15.9,13.7,5.6,17.7c3.6,6.9-4.3,8.3-4.3,16.4l0,0c0,8.7,1.8,8.7,1.8,17.4s10.8,11.3,0.2,17.5
    c-3.6,2.1-6.5,2.6-9.3,2.5c-5.6-0.3-11-3.4-20.9-3.4c-15,0-15-0.4-30-0.4s-15,2.2-30,2.2s-15,0.6-30,0.6s-15,0-30,0s-15-1.4-30-1.4
    s-15-1.8-30-1.8s-15,3.1-30,3.1s-15-3.2-30-3.2c-8.2,0-12,0-16.1,0.1c-1.3,0-2.6,0-4.2,0c-2.5,0-5.5,0-9.7,0c-15,0-15,3.2-30,3.2
    s-15-2.8-30-2.8c-14.9,0-15.3,2.8-29.6,1.5c-0.1,0.1-0.2,0.1-0.3,0.2c-13.5,1.2-13.8-0.8-27.4-0.8s-13.7,1.7-27.3,1.7
    s-13.7-1.4-27.3-1.4s-13.7,1.2-27.3,1.2s-17.7,4.5-27.4-1c-0.6-0.3-1.1-0.6-1.5-0.9c-7.3-4.9-1.2-7.4-1.2-14.8c0-0.5,0-0.9,0-1.3
    c0.3-6.5,3-7,3-14.4c0-7.8-1.3-7.8-1.3-15.7s1.2-7.8,1.2-15.7s-3-7.8-3-15.7c0-7.1,1.2-7.8,1.5-13.8c0-0.6,0-1.2,0-1.9
    c0-7.5-5.6-8.9-1.6-15.3c-9.3-3.6,0.3-9,0.3-16.6c0-8.1,2.9-8.1,2.9-16.3S35.2,388.2,35.2,380.1z M219,603.2
    c0,6.9-5.6,12.5-12.5,12.5h-163c-6.9,0-12.5-5.6-12.5-12.5s5.6-12.5,12.5-12.5h163C213.4,590.7,219,596.3,219,603.2z M133.5,773.7
    h-85c-9.7,0-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5h85c9.7,0,17.5,7.8,17.5,17.5S143.2,773.7,133.5,773.7z M562.5,711h-524
    c-4.1,0-7.5-3.4-7.5-7.5s3.4-7.5,7.5-7.5h524c4.1,0,7.5,3.4,7.5,7.5S566.6,711,562.5,711z M562.5,682h-524c-4.1,0-7.5-3.4-7.5-7.5
    s3.4-7.5,7.5-7.5h524c4.1,0,7.5,3.4,7.5,7.5S566.6,682,562.5,682z M562,653H39c-4.4,0-8-3.6-8-8s3.6-8,8-8h523c4.4,0,8,3.6,8,8
    S566.4,653,562,653z M425,96v100H175V96H425z M499,22h74c1.7,0,3,1.3,3,3s-1.3,3-3,3h-74c-1.7,0-3-1.3-3-3S497.3,22,499,22z M28,22
    h74c1.7,0,3,1.3,3,3s-1.3,3-3,3H28c-1.7,0-3-1.3-3-3S26.3,22,28,22z"/>
<rect x="175" y="96" className="newspaper-template1" width="250" height="100"/>
<path style={{fill: colors.primary_color}} d="M306.5,508.8c-11.4,7.2-12.7,6.2-22.7,16.2c-5.8,5.7-7.9,8.6-10.5,11.8c1.5,0,2.9,0,4.2,0
    c2.5-2,5.2-4.3,9.6-8.6C297,518.3,297.4,517.9,306.5,508.8z"/>
<path style={{fill: colors.primary_color}} d="M34.2,521.5c0,0.4,0,0.8,0,1.3c0,7.4-6.1,9.9,1.2,14.8c0-1.2,0-2.4,0-3.8C35.4,527.9,34.8,524.5,34.2,521.5z"
    />
<g className="newspaper-template3">
    <path className="newspaper-template2" d="M34,429.2c-4,6.5,1.6,7.8,1.6,15.3c0,0.7,0,1.3,0,1.9c4.3-6.6,9.1-6.5,16.9-14.2c9.4-9.4,9-9.8,18.5-19.1
        s11-7.8,20.5-17.1s9.6-9.2,19-18.6s9.1-9.7,18.5-19.1s9.4-9.4,18.8-18.8c8.9-8.9,23.2-7.6,32.1,1.2c10.5,10.4,11.5,9.4,22,19.8
        s11.9,9,22.3,19.4s6.9,13.9,17.4,24.3s10,10.9,20.4,21.3s12.9,7.9,23.4,18.3s9.3,11.5,19.8,21.9c10.4,10.3,22,5.3,22.2,19.7
        c13.6-13.6,13.8-13.7,27.7-27.5c7.8-7.8,17.6-11.4,27.2-15.3c9.9-4.1,19.5-9.6,30.1-9.6s20.9,3.8,30.8,7.9
        c9.6,3.9,20.2,7.8,28,15.6c9.5,9.4,9.5,9.4,19,18.8s6.2,12.7,15.7,22.1c9.4,9.4,10.6,8.2,20.1,17.6s9,9.9,18.4,19.3
        c4,3.9,7.6,5.4,10.8,6.6c2.8,0.2,5.7-0.4,9.3-2.5c10.6-6.1-0.2-8.8-0.2-17.5s-1.8-8.7-1.8-17.4l0,0c0-8.2,8-9.5,4.3-16.4
        c10.3-4-5.6-9.6-5.6-17.7c0-8.4,5.8-8.4,5.8-16.8s-2.2-8.4-2.2-16.8s-0.2-8.4-0.2-16.8s-3.3-8.4-3.3-16.8s1.6-8.4,1.6-16.8
        s-0.9-8.4-0.9-16.8s2.6-8.4,2.6-16.8s0-8.4,0-16.8s0.9-8.4,0.9-16.8s-2.1-8.4-2.1-16.8s2.3-8.4,2.3-16.8s-4.1-8.4-4.1-16.8
        s5.1-8.4,5.1-16.8s5.9-9.7-4.4-15.6c-10.4-6-12.5-2.7-27.1-2.7c-14.6,0-14.6,2.4-29.3,2.4s-14.6-1.8-29.3-1.8s-14.6,1.7-29.3,1.7
        c-14.6,0-14.6-1.5-29.3-1.5s-14.6,0-29.3,0s-14.6,2.5-29.3,2.5s-14.6-2.2-29.3-2.2s-14.6,1.3-29.3,1.3s-14.6-2.3-29.3-2.3
        s-14.6,1.8-29.3,1.8s-14.6-1.8-29.3-1.8s-14.6,0.8-29.3,0.8s-14.6,1-29.3,1s-14.6-2-29.3-2s-14.6,1.3-29.3,1.3s-14.6,0.2-29.3,0.2
        s-19.3-6.2-29.8-0.3c-10.1,5.6-1.5,8.5-1.5,16.6s4.5,8.1,4.5,16.2s-2.2,8.1-2.2,16.2s0.1,8.1,0.1,16.2s1.2,8.1,1.2,16.2
        s-0.5,8.1-0.5,16.2s-4,8.1-4,16.3s3.1,8.1,3.1,16.2s-1.9,8.1-1.9,16.3s2.1,8.1,2.1,16.3s-2.9,8.1-2.9,16.3
        C34.3,420.2,24.8,425.6,34,429.2z"/>
    <path className="newspaper-template4" d="M306.5,508.8c0.4-0.4,0.7-0.7,1.1-1.1c10.3-10.2,8.9-11.5,19.2-21.8c0.1-0.1,0.2-0.2,0.3-0.3
        c-0.2-14.4-11.8-9.4-22.2-19.7c-10.5-10.4-9.3-11.5-19.8-21.9s-12.9-7.9-23.4-18.3s-10-10.9-20.4-21.3s-6.9-13.9-17.4-24.3
        s-11.9-9-22.3-19.4s-11.5-9.4-22-19.8c-8.9-8.8-23.2-10.1-32.1-1.2c-9.4,9.4-9.4,9.4-18.8,18.8s-9.1,9.7-18.5,19.1
        s-9.6,9.2-19,18.6s-11,7.8-20.5,17.1s-9,9.8-18.5,19.1c-7.8,7.7-12.6,7.6-16.9,14.2c-0.2,6-1.5,6.6-1.5,13.8c0,7.8,3,7.8,3,15.7
        s-1.2,7.8-1.2,15.7s1.3,7.8,1.3,15.7c0,7.4-2.7,7.8-3,14.4c0.6,3,1.2,6.3,1.2,12.3c0,1.4,0,2.7,0,3.8c0.5,0.3,0.9,0.6,1.5,0.9
        c9.7,5.6,13.7,1,27.4,1s13.7-1.2,27.3-1.2s13.7,1.4,27.3,1.4s13.7-1.7,27.3-1.7s13.9,2,27.4,0.8c0.1-0.1,0.2-0.1,0.3-0.2
        c14.4,1.4,14.8-1.5,29.6-1.5c15,0,15,2.8,30,2.8s15-3.2,30-3.2c4.2,0,7.2,0,9.7,0c2.6-3.2,4.8-6.1,10.5-11.8
        C293.9,515,295.1,515.9,306.5,508.8z"/>
    <path className="newspaper-template5" d="M525.7,515.1c-9.5-9.4-10.7-8.2-20.1-17.6c-9.4-9.4-6.2-12.7-15.7-22.1s-9.5-9.4-19-18.8
        c-7.8-7.8-18.4-11.6-28-15.6c-9.9-4.1-20.2-7.9-30.8-7.9s-20.2,5.5-30.1,9.6c-9.6,3.9-19.4,7.6-27.2,15.3
        c-13.9,13.8-14,13.9-27.7,27.5c-0.1,0.1-0.2,0.2-0.3,0.3c-10.3,10.2-8.9,11.6-19.2,21.8c-0.4,0.4-0.8,0.8-1.1,1.1
        c-9.2,9.1-9.6,9.5-19.4,19.3c-4.4,4.4-7.1,6.6-9.6,8.6c4.2,0,7.9-0.1,16.1-0.1c15,0,15,3.2,30,3.2s15-3.1,30-3.1s15,1.8,30,1.8
        s15,1.4,30,1.4s15,0,30,0s15-0.6,30-0.6s15-2.2,30-2.2s15,0.4,30,0.4c10,0,15.4,3.1,20.9,3.4c-3.2-1.3-6.9-2.7-10.8-6.6
        C534.6,525,535.1,524.5,525.7,515.1z"/>
</g>
<path style={{fill: colors.primary_color}} d="M43.5,590.7c-6.9,0-12.5,5.6-12.5,12.5s5.6,12.5,12.5,12.5h163c6.9,0,12.5-5.6,12.5-12.5s-5.6-12.5-12.5-12.5
    H43.5z"/>
<path className="newspaper-template6" d="M133.5,738.7h-85c-9.7,0-17.5,7.8-17.5,17.5s7.8,17.5,17.5,17.5h85c9.7,0,17.5-7.8,17.5-17.5
    S143.2,738.7,133.5,738.7z"/>
<path className="newspaper-template6" d="M562.5,667h-524c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h524c4.1,0,7.5-3.4,7.5-7.5S566.6,667,562.5,667z"/>
<path className="newspaper-template6" d="M562.5,696h-524c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h524c4.1,0,7.5-3.4,7.5-7.5S566.6,696,562.5,696z"/>
<path className="newspaper-template6"
    d="M562,637H39c-4.4,0-8,3.6-8,8s3.6,8,8,8h523c4.4,0,8-3.6,8-8S566.4,637,562,637z"/>
<path style={{fill: colors.primary_color}} d="M28,28h74c1.7,0,3-1.3,3-3s-1.3-3-3-3H28c-1.7,0-3,1.3-3,3S26.3,28,28,28z"/>
<path style={{fill: colors.primary_color}} d="M499,28h74c1.7,0,3-1.3,3-3s-1.3-3-3-3h-74c-1.7,0-3,1.3-3,3S497.3,28,499,28z"/>
</g>
</svg>

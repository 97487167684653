import * as React from 'react'
import './text-icon.css'

export const TextIcon = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
    <g id="Group_6125" data-name="Group 6125" transform="translate(-1061 -294)">
        <g id="Group_6124" data-name="Group 6124">
            <path id="Rectangle_2235" d="M0 0h50v50H0z" className="text-icon-1" data-name="Rectangle 2235" transform="translate(1061 294)"/>
        </g>
        <g id="Group_6120" data-name="Group 6120">
            <g id="Group_5873" data-name="Group 5873" transform="translate(1072.484 307)">
                <g id="T" className="text-icon-2">
                    <g id="Group_5873-2" className="text-icon-2" data-name="Group 5873">
                        <path id="Path_12094" d="M497.335 294.7h-6.477v-3.988h17.483v3.988h-6.447v16.012h-4.56z"
                        className="text-icon-3" data-name="Path 12094" transform="translate(-490.858 -290.709)"/>
                    </g>
                </g>
                <g id="T-2" className="text-icon-2" transform="translate(15.582 7.195)">
                    <g id="Group_5874" className="text-icon-2" data-name="Group 5874">
                        <path id="Path_12095" d="M503.045 297.931H498.9v-2.553h11.194v2.553h-4.128v10.252h-2.92z"
                        className="text-icon-3" data-name="Path 12095" transform="translate(-498.898 -295.378)"/>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>

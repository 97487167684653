import * as React from 'react'
import classnames from 'classnames'
import '@rasa.io/ui-components/components/icons/fills.css'

export const Upload = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" {...props} className={classnames('upload', props.className)} viewBox="0 0 30.383 28.158">
  <g>
    <path d="M29.511 228.279a.867.867 0 0 0-.87.87v7.918a3.906 3.906 0 0 1-3.9 3.9H5.642a3.906 3.906 0 0 1-3.9-3.9v-8.047a.87.87 0 1 0-1.741 0v8.047a5.648 5.648 0 0 0 5.642 5.642h19.1a5.648 5.648 0 0 0 5.642-5.642v-7.918a.871.871 0 0 0-.874-.87z" className="fill-dark" transform="translate(0 -214.552)"/>
    <path d="M137.806 24.272l4.049-4.049v17.66a.87.87 0 1 0 1.741 0V20.222l4.049 4.049a.876.876 0 0 0 .613.258.839.839 0 0 0 .613-.258.868.868 0 0 0 0-1.232l-5.532-5.532a.881.881 0 0 0-.613-.258.843.843 0 0 0-.613.258l-5.532 5.532a.869.869 0 0 0 1.225 1.232z" className="fill-dark" transform="translate(-127.535 -17.25)"/>
  </g>
</svg>
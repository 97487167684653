import * as React from 'react'
import './visa.css'

export const VisaIcon = (props: any) =>

<svg xmlns="http://www.w3.org/2000/svg" width="33.332" height="23.809" viewBox="0 0 33.332 23.809" className="visa-icon">
    <g id="Group_6428" data-name="Group 6428" transform="translate(-92 -200)">
        <g id="Group_6423" data-name="Group 6423">
            <path id="Path_11952" d="M36.332 29.634a3.175 3.175 0 0 1-3.174 3.174H6.174A3.175 3.175 0 0 1 3 29.634v-17.46A3.175 3.175 0 0 1 6.174 9h26.984a3.175 3.175 0 0 1 3.174 3.174z" className="cls-1" data-name="Path 11952" transform="translate(89 191)"/>
        </g>
        <path id="Path_11953" d="M13.729 19l-2.084 6.216s-.529-2.629-.582-2.959C9.877 19.549 8.126 19.7 8.126 19.7l2.063 8.03H12.7L16.167 19zm1.986 8.73h2.278L19.372 19h-2.307zM31.841 19h-2.4l-3.738 8.73h2.263l.467-1.247h2.854l.243 1.247h2.074zm-2.774 5.816l1.24-3.3.649 3.3zM22.6 21.544c0-.481.4-.839 1.529-.839a3.6 3.6 0 0 1 1.58.535l.37-1.832A6.956 6.956 0 0 0 23.947 19c-2.4 0-3.632 1.146-3.632 2.6 0 2.624 3.158 2.264 3.158 3.612 0 .231-.183.765-1.5.765a4.878 4.878 0 0 1-2.19-.483l-.393 1.759a5.353 5.353 0 0 0 2.474.481c1.634 0 3.9-1.222 3.9-2.978.003-2.117-3.164-2.269-3.164-3.212z" className="cls-2" data-name="Path 11953" transform="translate(87.942 188.936)"/>
        <path id="Path_11954" d="M10.772 23.753l-.767-3.768a1.408 1.408 0 0 0-1.248-.817H5.233s4.493 1.327 5.539 4.585z" className="cls-3" data-name="Path 11954" transform="translate(88.539 188.902)"/>
    </g>
</svg>

import * as React from 'react'
import './topics.css'

export const Topics = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" width={props.svgwidth} height={props.svgheight} className="panel-icon" viewBox="0 0 18.67 38.668">

    <g id="Group_6291" data-name="Group 6291" transform="translate(-47.63 -317.769)">
        <g id="Group_6264" data-name="Group 6264">
            <g id="Group_6256" data-name="Group 6256" transform="translate(47.489 320.125)">
                <path id="Path_12137" d="M640.8 185.6a8.179 8.179 0 0 0-5.377 14.349c1.421 1.421 1.662 3.838 1.692 4.954h7.4c.03-1.087.241-3.414 1.571-4.833a.293.293 0 0 1 .12-.15 8.177 8.177 0 0 0-5.406-14.32z" className="topics-1" data-name="Path 12137" transform="translate(-630.576 -175.377)"/>
                <path id="Path_12138" d="M643.517 203.936a.239.239 0 0 0-.241-.242h-7.432a.242.242 0 1 0 0 .483h7.432c.15 0 .271-.091.241-.241z" className="topics-2" data-name="Path 12138" transform="translate(-630.084 -172.397)"/>
                <path id="Path_12139" d="M642.03 206.6h-5.23a.242.242 0 1 0 0 .483h5.226a.221.221 0 0 0 .241-.241.238.238 0 0 0-.237-.242z" className="topics-2" data-name="Path 12139" transform="translate(-629.926 -171.919)"/>
                <path id="Path_12140" d="M640.32 183.514a9.333 9.333 0 0 0-6.162 16.344c1.48 1.48 1.329 4.621 1.329 4.652a.723.723 0 0 0 .151.424.6.6 0 0 0 .423.181h8.55a.6.6 0 0 0 .423-.181.57.57 0 0 0 .15-.424c0-.03-.15-3.171 1.33-4.652.03-.03.061-.061.061-.091a9.327 9.327 0 0 0-6.254-16.253zm5.288 15.648c-1.33 1.42-1.541 3.747-1.571 4.833h-7.4c-.03-1.117-.273-3.534-1.692-4.953a8.184 8.184 0 1 1 13.565-6.163 8.124 8.124 0 0 1-2.78 6.133.292.292 0 0 0-.123.15z" className="topics-3" data-name="Path 12140" transform="translate(-630.844 -175.72)"/>
                <path id="Path_12141" d="M643.468 202.709h-7.462a1.389 1.389 0 1 0 0 2.779h7.432a1.39 1.39 0 1 0 .03-2.779zm-7.462 1.631a.242.242 0 1 1 0-.483h7.432a.239.239 0 0 1 .241.242c.03.15-.091.241-.241.241z" className="topics-3" data-name="Path 12141" transform="translate(-630.246 -172.559)"/>
                <path id="Path_12142" d="M642.192 205.614h-5.226a1.389 1.389 0 1 0 0 2.779h5.226a1.386 1.386 0 0 0 1.39-1.393 1.405 1.405 0 0 0-1.39-1.386zm0 1.631h-5.226a.242.242 0 1 1 0-.483h5.226a.238.238 0 0 1 .241.242.221.221 0 0 1-.241.241z" className="topics-3" data-name="Path 12142" transform="translate(-630.088 -172.081)"/>
                <g id="Group_6255" data-name="Group 6255" transform="translate(8.051 12.787)">
                    <path id="Rectangle_2109" d="M0 0h2.797v2.698H0z" className="topics-3" data-name="Rectangle 2109" transform="translate(0 10.345)"/>
                    <path id="Path_12146" d="M640.4 196.757l.039-8.956h-2.561l.039 8.956z" className="topics-3" data-name="Path 12146" transform="translate(-637.76 -187.801)"/>
                </g>
                <g id="Group_6263" data-name="Group 6263" transform="translate(-44.489 -541.125)">
                    <path id="Path_404-2" d="M.533 0A.792.792 0 0 0 0 .178c-.008.357.174.357.174.714s.061.357.061.713.153.356.153.713-.016.356-.016.713-.134.357-.134.713-.179.356-.179.714-.059.358-.041.715a.9.9 0 0 0 .6.169c.147-.012.147-.105.174-.172.012-.356.15-.356.15-.712S.813 4.1.813 3.745s.068-.356.068-.712S.8 2.676.8 2.319s.262-.357.262-.713S.983 1.249.983.892 1.111.534 1.1.178A.916.916 0 0 0 .533 0z" className="topics-4" data-name="Path 404-2" transform="rotate(33 -883.757 374.952)"/>
                    <path id="Path_404-2-2" d="M.533 0A.783.783 0 0 0 0 .182c-.008.363.174.364.174.727s.061.363.061.727.153.363.153.727-.016.363-.016.727-.134.364-.134.727-.179.364-.179.728S0 4.91.018 5.273a.885.885 0 0 0 .6.172c.147-.013.147-.107.174-.175.012-.363.15-.363.15-.726s-.129-.363-.129-.727.068-.363.068-.726S.8 2.728.8 2.364s.262-.364.262-.727S.983 1.274.983.91 1.111.545 1.1.181A.9.9 0 0 0 .533 0z" className="topics-4" data-name="Path 404-2" transform="rotate(-31 1000.74 190.783)"/>
                    <path id="Path_405-2" d="M.381 0C.19 0 0 .3 0 .561c0 .864.08.864.08 1.729s.02.867.02 1.732-.013.866-.011 1.732c.006.257.11.4.3.389a.385.385 0 0 0 .374-.389c0-.864-.019-.864-.019-1.729S.66 3.158.66 2.293.765 1.426.765.561C.765.3.593.01.4.01.373 0 .381 0 .381 0z" className="topics-4" data-name="Path 405-2" transform="translate(53.648 538.769)"/>
                </g>
            </g>
        </g>
    </g>
</svg>
